/* storybook-check-ignore */
import { Box, Button, IconButton, Icons, Text, TextButton, useMedia } from '@opendoor/bricks-next';
import { centsToCurrencyString } from '@opendoor/bricks/helpers/currencyHelpers';

import { useReturnExperienceContext } from 'helpers/ReturnExperienceContext';

import { ExperienceType } from '../../helpers/returnBanner';
import { CONSUMER_FE_URL, COSMOS_URL } from '../globals';

type CTAButtonProps = {
  experience: ExperienceType;
  loading?: boolean;
  onClick?: () => void;
  url?: string;
  actionText: string;
};

const CTAButton = (props: CTAButtonProps): JSX.Element => {
  return (
    <>
      <Button
        $smallerThanMD={{ display: 'none', size: 'xsmall' }}
        maxWidth="auto"
        variant="primary"
        size="small"
        analyticsName={`cosmos-return-banner-${ExperienceType[props.experience]}`}
        trackImpression
        aria-label="return-banner-cta"
        loading={props.loading}
        href={props.url}
        label={props.actionText}
      ></Button>
      <IconButton
        $largerThanSM={{ display: 'none' }}
        analyticsName={`cosmos-return-experience-${ExperienceType[props.experience]}-icon-button`}
        icon={{ Component: Icons.RightArrow, label: `${props.actionText}` }}
        onPress={() => props.url && window.location.assign(props.url)}
        size="small"
        variant="default"
      />
    </>
  );
};

const AltCTAButton = (props: CTAButtonProps): JSX.Element => {
  return (
    <>
      <TextButton
        $smallerThanLG={{ display: 'none', size: 'xsmall' }}
        variant="primary"
        size="small"
        label={props.actionText}
        href={props.url}
        aria-label="return-experience-cta"
        trackImpression
        analyticsName={`cosmos-return-experience-${ExperienceType[props.experience]}-text-button`}
      ></TextButton>
      <IconButton
        $largerThanMD={{ display: 'none' }}
        analyticsName={`cosmos-return-experience-${ExperienceType[props.experience]}-icon-button`}
        icon={{ Component: Icons.RightArrow, label: `${props.actionText}` }}
        onPress={() => props.url && window.location.assign(props.url)}
        size="small"
        variant="default"
      />
    </>
  );
};

const getActiveOfferExpiryText = (daysToExpire = 10) => {
  let expiryText = 'is waiting';
  if (daysToExpire < 1) {
    expiryText = 'expires today';
  } else if (daysToExpire < 2) {
    expiryText = 'expires in 1 day';
  } else if (daysToExpire < 3) {
    expiryText = 'expires in 2 days';
  } else if (daysToExpire === 3) {
    expiryText = 'expires in 3 days';
  }
  return expiryText;
};

type HeadlineProps = {
  experience: ExperienceType;
  currentOfferPriceCents?: number;
  previousOfferPriceCents?: number;
  daysToExpire?: number;
  smallerThanMD: boolean;
  isHeroVariant: boolean;
};

const Headline = (props: HeadlineProps): JSX.Element | null => {
  const currencyTypography = props.isHeroVariant ? '$labelMedium' : '$labelLarge';
  switch (props.experience) {
    case ExperienceType.offer_expired:
      return <>Get your updated offer{props.smallerThanMD ? '' : ' today'}</>;
    case ExperienceType.incomplete_flow:
      return <>Finish your offer{props.smallerThanMD ? '' : ' today'}</>;
    case ExperienceType.offer_increased:
      return !isNaN(props.currentOfferPriceCents ?? NaN) &&
        !isNaN(props.previousOfferPriceCents ?? NaN) ? (
        <>
          Your offer increased by{' '}
          <Text tag="span" color="$backgroundAccentBrand" typography={currencyTypography}>
            {centsToCurrencyString(
              (props.currentOfferPriceCents ?? 0) - (props.previousOfferPriceCents ?? 0),
            )}
          </Text>
        </>
      ) : null;
    case ExperienceType.offer_expiring:
    case ExperienceType.offer_decreased:
    case ExperienceType.active_offer:
      return !isNaN(props.currentOfferPriceCents ?? NaN) ? (
        <>
          Your offer of{' '}
          <Text tag="span" color="$backgroundAccentBrand" typography={currencyTypography}>
            {centsToCurrencyString(props.currentOfferPriceCents ?? 0)}
          </Text>{' '}
          {getActiveOfferExpiryText(props.daysToExpire)}
        </>
      ) : null;
    case ExperienceType.home_insights:
      return <>Get back to your{props.smallerThanMD ? <br /> : ' '}home insights</>;
    default:
      return null;
  }
};

type ExperienceCTAButtonProps = {
  experience: ExperienceType;
  sellerInputUUID?: string;
  customerUUID?: string;
  addressToken?: string;
  smallerThanMD?: boolean;
  sellerFlowLastCompletedSlug?: string;
  isHeroVariant: boolean;
};

const ExperienceCTAButton = (props: ExperienceCTAButtonProps): JSX.Element | null => {
  let offerDashUrl = COSMOS_URL;
  if (props.addressToken) {
    // Return to sell tab in hub for returning customers
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/sell/${props.addressToken}`;
  } else if (props.sellerInputUUID) {
    offerDashUrl = `${CONSUMER_FE_URL}/dashboard/${props.sellerInputUUID}/overview`;
  }

  switch (props.experience) {
    case ExperienceType.offer_expired: {
      const baseUrl = offerDashUrl;
      const params = new URLSearchParams({
        refresh_offer: 'true',
        refresh_source: 'homepage_experience',
      }).toString();
      const refreshUrl = `${baseUrl}?${params}`;
      return props.isHeroVariant ? (
        <AltCTAButton
          actionText={`Refresh${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={refreshUrl}
          experience={props.experience}
        />
      ) : (
        <CTAButton
          actionText={`Refresh${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={refreshUrl}
          experience={props.experience}
        />
      );
    }
    case ExperienceType.incomplete_flow:
      // eslint-disable-next-line no-case-declarations
      const sellerFlowUrl = `${CONSUMER_FE_URL}/seller-lead/${props.sellerInputUUID}/${props.sellerFlowLastCompletedSlug}`;
      return props.isHeroVariant ? (
        <AltCTAButton
          actionText={`Resume${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={sellerFlowUrl}
          experience={props.experience}
        />
      ) : (
        <CTAButton
          actionText={`Resume${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={sellerFlowUrl}
          experience={props.experience}
        />
      );
    case ExperienceType.offer_increased:
    case ExperienceType.offer_expiring:
    case ExperienceType.offer_decreased:
    case ExperienceType.active_offer:
      return props.isHeroVariant ? (
        <AltCTAButton
          actionText={`View${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={offerDashUrl}
          experience={props.experience}
        />
      ) : (
        <CTAButton
          actionText={`View${props.smallerThanMD ? ' ' : ' your '}offer`}
          url={offerDashUrl}
          experience={props.experience}
        />
      );
    case ExperienceType.home_insights:
      // eslint-disable-next-line no-case-declarations
      const homeInsightsUrl = `${CONSUMER_FE_URL}/home-insights`;
      return props.isHeroVariant ? (
        <AltCTAButton
          actionText={`View insights`}
          url={homeInsightsUrl}
          experience={props.experience}
        />
      ) : (
        <CTAButton
          actionText={`View insights`}
          url={homeInsightsUrl}
          experience={props.experience}
        />
      );
    default:
      return null;
  }
};

export const ReturnExperienceBanner = () => {
  const { returnExperienceData } = useReturnExperienceContext();

  const { smallerThanMD } = useMedia();

  if (!returnExperienceData || returnExperienceData?.experienceType === null) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      zIndex={5}
      top={73}
      px={15}
      py={10}
      width={360}
      $largerThanSM={{
        width: 646,
        py: 15,
        pl: 32,
        top: 80,
      }}
      backgroundColor="$backgroundSecondary"
      borderColor="$borderStateInactiveTertiary"
      borderRadius={24}
      justifyContent="space-between"
      alignItems="center"
      className="hp-reskin-syw-return-experience-banner-wrapper"
    >
      <Box mt={10}>
        <Text tag="span" color="$contentPrimary" typography="$labelLarge">
          <Headline
            experience={returnExperienceData.experienceType}
            currentOfferPriceCents={returnExperienceData.currentOfferHeadlinePriceCents}
            previousOfferPriceCents={returnExperienceData.previousOfferHeadlinePriceCents}
            daysToExpire={returnExperienceData.offerExpiresInDays}
            smallerThanMD={smallerThanMD}
            isHeroVariant={false}
          />
        </Text>
        <Text mt={4} tag="span" color="$contentSecondary" typography="$bodyMedium">
          {returnExperienceData.address}
        </Text>
      </Box>
      <ExperienceCTAButton
        experience={returnExperienceData.experienceType}
        sellerInputUUID={returnExperienceData.sellerInputUUID}
        customerUUID={returnExperienceData.customerUUID}
        addressToken={returnExperienceData.addressToken}
        smallerThanMD={smallerThanMD}
        sellerFlowLastCompletedSlug={returnExperienceData.sellerFlowLastCompletedSlug}
        isHeroVariant={false}
      />
    </Box>
  );
};

export const ReturnExperienceHero = () => {
  const { returnExperienceData } = useReturnExperienceContext();
  const { smallerThanSM } = useMedia();

  if (!returnExperienceData || returnExperienceData?.experienceType === null) {
    return null;
  }

  return (
    <Box
      animation="contentfulArchAnimation"
      animateOnly={['opacity', 'transform']}
      enterStyle={{ y: -30, opacity: 0 }}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      top={-27}
      p={16}
      pt={43}
      analyticsName="cosmos-return-experience-address-entry-slide-in"
      backgroundColor={'$backgroundSecondary'}
      borderBottomRightRadius={20}
      borderBottomLeftRadius={20}
    >
      <Box gap={5}>
        <Text tag="span" color="$contentPrimary" typography={'$labelMedium'}>
          <Headline
            experience={returnExperienceData.experienceType}
            currentOfferPriceCents={returnExperienceData.currentOfferHeadlinePriceCents}
            previousOfferPriceCents={returnExperienceData.previousOfferHeadlinePriceCents}
            daysToExpire={returnExperienceData.offerExpiresInDays}
            smallerThanMD={smallerThanSM}
            isHeroVariant={true}
          />
        </Text>
        <Text tag="span" color="$contentSecondary" typography="$bodySmall">
          {returnExperienceData.address}
        </Text>
      </Box>
      <ExperienceCTAButton
        experience={returnExperienceData.experienceType}
        sellerInputUUID={returnExperienceData.sellerInputUUID}
        customerUUID={returnExperienceData.customerUUID}
        addressToken={returnExperienceData.addressToken}
        smallerThanMD={false}
        isHeroVariant={true}
      />
    </Box>
  );
};
