import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';

import * as api from '../components/api';

const fetchSellerReturnExperience = async () => {
  const sellerInputUUID = Cookies.get('sellerInputUUID') || localStorage.getItem('sellerInputUUID');

  if (!sellerInputUUID) {
    return;
  }

  localStorage.setItem('sellerInputUUID', sellerInputUUID);

  const response = await api.fetchSellerReturnExperience(sellerInputUUID);
  if (!response || !response.data) {
    return;
  }
  // check for errors after parsing response
  // fetchSellerReturnExperience queries reception, web, and seller
  // any one of those calls could fail depending on where the user is in the flow

  // e.g. no leads are generated if user is in the middle of onboarding
  // so for incomplete_flow, the seller part of gql response will error, as expected

  // therefore we parse the response in getReturnExperienceData() and return early
  // if there is no eligible experience
  const returnExperienceData = getReturnExperienceData(response.data, sellerInputUUID);
  if (returnExperienceData.experienceType === null) {
    return;
  }

  return {
    returnExperienceData,
  };
};

const buildAddress = (
  street: string,
  unitType: string,
  unitNumber: string,
  city: string,
  state: string,
  postalCode: string,
): string => {
  let address = '';

  if (street && unitNumber) {
    address = `${street} ${unitType} ${unitNumber},`;
  } else if (street) {
    address = `${street}`;
  }

  if (city) {
    address += `, ${city},`;
  }

  if (state) {
    address += ` ${state}`;
  }

  if (postalCode) {
    address += ` ${postalCode}`;
  }

  return address;
};

export enum ExperienceType {
  offer_expired,
  incomplete_flow,
  offer_expiring,
  offer_decreased,
  offer_increased,
  active_offer,
  home_insights,
}

export interface ReturnExperienceData {
  address?: string;
  addressFull?: string;
  addressToken?: string;
  customerUUID?: string;
  firstName?: string;
  offerRequestSource?: string;
  currentOfferHeadlinePriceCents?: number;
  previousOfferHeadlinePriceCents?: number;
  offerExpiresInDays?: number;
  sellerInputUUID?: string;
  experienceType: ExperienceType | null;
  sellerFlowLastCompletedSlug?: string;
}

const OFFER_EXPIRY_DAYS_CUTOFF = 4;

export const getReturnExperienceData = (
  data: any,
  sellerInputUUID: string,
): ReturnExperienceData => {
  if (!data.sellDirect?.getLead && !data.reception?.getSellerInput) {
    return {
      experienceType: null,
    };
  }
  const answers = data.reception.getSellerInput?.sellerInput.answers;

  const productOfferings: Array<any> | undefined =
    data.reception.getSellerInput?.sellerInput.productOfferings;
  const sellDirectProductOffering = productOfferings?.find(
    (po) => po.product === 'OPENDOOR_DIRECT',
  );
  const sellDirectDenied = sellDirectProductOffering?.deniedAt;

  const homeInsightsProductOffering = productOfferings?.find(
    (po) => po.product === 'HOME_INSIGHTS',
  );
  const homeInsightsDenied = homeInsightsProductOffering?.deniedAt;

  // If the lead is denied for sell direct and home insights then there is no return experience for them.
  if (sellDirectDenied && homeInsightsDenied) {
    return {
      experienceType: null,
    };
  }

  const sellerInputAddress = data.reception.getSellerInput?.sellerInput.address;
  const addressFull = buildAddress(
    sellerInputAddress.street,
    sellerInputAddress.unitType,
    sellerInputAddress.unitNumber,
    sellerInputAddress.city,
    sellerInputAddress.state,
    sellerInputAddress.postalCode,
  );

  // Without an email the lead could not have qualified and thus we want to show the incomplete experience at this stage
  if (!answers.email) {
    return {
      sellerInputUUID: sellerInputUUID,
      address: data.reception.getSellerInput?.sellerInput.address.street,
      addressFull: addressFull,
      experienceType: ExperienceType.incomplete_flow,
      sellerFlowLastCompletedSlug:
        data.reception.getSellerInput?.sellerInput.sellerFlowLastCompletedSlug,
    };
  }

  // Check if the lead was qualified in web
  const hasSellDirectExperience = data.sellDirect.getLead?.lead.isQualified;

  // Check for a home insights experience
  const hasHomeInsightsExperience = answers.homeGuideRegistered && !homeInsightsDenied;

  if (!hasSellDirectExperience && !hasHomeInsightsExperience) {
    return {
      experienceType: null,
    };
  }

  // user doesn't have an offer but does have home insights
  if (!hasSellDirectExperience) {
    return {
      sellerInputUUID,
      address: data.reception.getSellerInput?.sellerInput.address.street,
      addressFull: addressFull,
      experienceType: ExperienceType.home_insights,
    };
  }

  // user has a sell direct offer
  const lead = data.sellDirect.getLead?.lead;

  const address = lead.address?.street;
  const addressToken = lead.address?.uuid;
  const customerUUID = lead.customerV2?.uuid;
  const firstName = lead.customerV2?.firstName;
  const offerRequestSource = lead.latestOffer?.offerRequestSource;
  const currentOfferHeadlinePriceCents = lead.latestOffer?.headlinePriceCents;
  const previousOfferHeadlinePriceCents = lead.previousOffer?.headlinePriceCents;
  const offerState = lead.latestOffer?.state;
  const offerExpiresAt = lead.latestOffer?.preliminaryOfferExpirationDate;
  const isQualifiedLead = lead.isQualified;

  let offerExpiresInDays = undefined;
  if (offerExpiresAt) {
    const currentTime = new Date().getTime();
    const expirationTime = Date.parse(offerExpiresAt);
    // convert the milliseconds time difference into days and round up to nearest number of days
    offerExpiresInDays = Math.ceil((expirationTime - currentTime) / (1000 * 3600 * 24));
  }

  let experienceType = null;

  if (offerState == 'EXPIRED' && isQualifiedLead) {
    // https://github.com/opendoor-labs/code/blob/f6fb69d0fb84941597fb8084c470f9eac8aed67d/js/packages/cosmos/src/components/shared/ReturnExperienceBanner.tsx#L306 -> we show the refresh offer button if the experience type is "offer_expired"
    // let's only set the experience type to be "offer_expired" when we know that this lead is eligible for offer refresh (qualified for opendoor direct)
    experienceType = ExperienceType.offer_expired;
  } else if (
    offerState == 'SENT' &&
    offerExpiresInDays &&
    offerExpiresInDays > 0 &&
    offerExpiresInDays <= OFFER_EXPIRY_DAYS_CUTOFF
  ) {
    experienceType = ExperienceType.offer_expiring;
  } else if (
    offerState == 'SENT' &&
    currentOfferHeadlinePriceCents &&
    previousOfferHeadlinePriceCents &&
    currentOfferHeadlinePriceCents - previousOfferHeadlinePriceCents >= 1000
  ) {
    experienceType = ExperienceType.offer_increased;
  } else if (
    offerState == 'SENT' &&
    currentOfferHeadlinePriceCents &&
    previousOfferHeadlinePriceCents &&
    currentOfferHeadlinePriceCents < previousOfferHeadlinePriceCents
  ) {
    experienceType = ExperienceType.offer_decreased;
  } else if (offerState == 'SENT') {
    experienceType = ExperienceType.active_offer;
  }

  return {
    sellerInputUUID,
    address,
    addressFull,
    addressToken,
    customerUUID,
    firstName,
    offerRequestSource,
    currentOfferHeadlinePriceCents,
    previousOfferHeadlinePriceCents,
    offerExpiresInDays,
    experienceType,
  };
};

// Custom hook to handle fetching return experience logic
export const useReturnExperience = () => {
  const [returnExperienceData, setReturnExperienceData] = useState<ReturnExperienceData>({
    experienceType: null,
  });
  const returnExperienceBannerShown = returnExperienceData.experienceType !== null;
  useEffect(() => {
    async function start() {
      const result = await fetchSellerReturnExperience();
      if (!result) {
        return;
      }
      setReturnExperienceData(result.returnExperienceData);
    }

    start();
  }, []);

  return {
    returnExperienceData,
    returnExperienceBannerShown,
  };
};
