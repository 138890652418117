import { useInView } from 'react-intersection-observer';
import LazyLoad from 'react-lazyload';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAuth } from '@opendoor/auth-fe';
import { Box, Flex, Heading } from '@opendoor/bricks/core';
import { Entry } from 'contentful';

import { Desc } from 'components/landing-pages-v2/shared/Typography';

import { useReturnExperienceContext } from 'helpers/ReturnExperienceContext';

import { Awaited, EntryComponent, LoaderFn } from '../../cms/entries/entries';
import { ILandingPageFields, ILpComponentHeroV2WithMarkers } from '../../declarations/contentful';
import AddressSearch from '../shared/AddressSearch';
import NewSellerFlowAddressInput from '../shared/NewSellerFlowAddressInput';
import {
  generatePresetPictureSet,
  generatePresetPictureSetProps,
  PictureSourceSpec,
} from '../shared/performance/pictures';
import Grid, { gridStyles } from './Grid';
import { HERO_IMAGE_DESKTOP_PROPS, HERO_IMAGE_MOBILE_PROPS, PressBox } from './HeroV2';
import { ReturnExperienceHero } from './ReturnExperience';
import Marker, { MarkerFadeIn } from './shared/Marker';
import { StickyCTAUtility } from './StickyCTAUtility/StickyCTAUtility';

const HeroImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '540px 540px 0px 0px',
  aspectRatio: '4 / 5',
  '@media (max-width: 767px)': {
    minHeight: '439px',
  },
  '@media (min-width: 768px) and (max-width: 1439px)': {
    borderRadius: '540px 540px 36px 36px',
    minHeight: '432px',
  },
  '@media (min-width: 1440px)': {
    borderRadius: '540px 540px 36px 36px',
    minHeight: '773px',
  },
  overflow: 'hidden',
});

const RenderHeroV2WithMarkers = (
  entry: ILpComponentHeroV2WithMarkers,
  resolvedData?: Awaited<ReturnType<typeof heroV2WithMarkersLoader>>,
) => {
  const { fields } = entry;
  const { authentication } = useAuth();
  const hideStickyCta =
    resolvedData?.stickyCTA === 'None' || authentication.state === 'authenticated';
  const headlineLength = fields?.title?.length || 0;
  const longHeadline = headlineLength > 20;
  const headlineStylesDesktop = longHeadline
    ? css`
        @media (min-width: 1049px) {
          line-height: 100%;
          font-size: 80px;
          letter-spacing: -3.264px;
          text-wrap: balance;
        }
      `
    : css`
        @media (min-width: 1049px) {
          line-height: 100%;
          font-size: 96px;
          letter-spacing: -3.264px;
          text-wrap: wrap;
        }
      `;
  const {
    ref: addressEntryRef,
    inView: addressEntryInView,
    entry: intersectionObserverEntry,
  } = useInView();
  // For the first intersection observer event, the entry is undefined
  // as the element loads. We want to make sure not to flash the sticky
  // cta while it loads for the first time.
  const showStickyCTAUtility = Boolean(intersectionObserverEntry) && !addressEntryInView;

  const { returnExperienceExperimentVariant, showReturnExperience } = useReturnExperienceContext();
  const showReturnExperienceHeroVariant =
    showReturnExperience && returnExperienceExperimentVariant === 'treatment';

  return (
    <Box
      analyticsName={`cosmos-landing-page-hero-v2-with-markers-${fields?.analyticsId}`}
      trackImpression
      inViewThreshold={0.3}
    >
      {!hideStickyCta && (
        <StickyCTAUtility analyticsId={fields?.analyticsId} isVisible={showStickyCTAUtility} />
      )}
      <Grid mr={[null, null, 9, 11, 11]} ml={[null, null, 9, 11, 11]}>
        <Box gridColumn={gridStyles.colSpan12}>
          <Flex
            zIndex="1"
            flexDirection={['column', null, 'row']}
            justifyContent="space-between"
            width="100%"
            columnGap={[null, null, 4]}
            position="relative"
          >
            <Flex
              id="title-and-address-entry"
              flexDirection="column"
              width={['100%', null, '50%']}
              px={[6, 6, 0]}
            >
              <Flex
                marginTop={[6, null, '104px']}
                flexDirection="column"
                marginBottom={[4, null, 8]}
                marginLeft={0}
                width="100%"
                alignItems={['center', null, 'unset']}
              >
                <Heading
                  as="h1"
                  color="neutrals100"
                  fontWeight="medium"
                  marginTop={[null, 5, 0, 10]}
                  marginBottom={3}
                  textAlign={['center', null, 'left']}
                  maxWidth={['275px', '275px', '480px', '585px']}
                  lineHeight={'100'}
                  css={css`
                    line-height: 100%;
                    font-size: 44px;
                    letter-spacing: -1.32px;
                    @media (min-width: 376px) and (max-width: 768px) {
                      font-size: 44px;
                      line-height: 100%;
                      letter-spacing: -1.32px;
                    }
                    @media (min-width: 769px) and (max-width: 1048px) {
                      font-size: 56px;
                      line-height: 100%;
                      letter-spacing: -1.904px;
                    }
                    @media (min-width: 789px) and (max-width: 1048px) {
                      max-width: 344px;
                    }
                    ${headlineStylesDesktop}
                  `}
                >
                  {fields?.title}
                </Heading>
                <Desc
                  textAlign="center"
                  maxWidth={235}
                  mt={10}
                  $largerThanSM={{ textAlign: 'left', maxWidth: 305 }}
                  $largerThanMD={{ fontSize: 24, maxWidth: 410 }}
                >
                  {fields?.subtitle}
                </Desc>
              </Flex>
              <Box visibility="hidden" ref={addressEntryRef} />
              <Flex justifyContent={['center', null, 'flex-start']} flexDir={'column'}>
                {fields?.showAddressInputCta && (
                  <>
                    <Box
                      width="85%"
                      paddingTop={[6, null, 'unset']}
                      css={css`
                        min-width: 325px;
                        @media (max-width: 767px) {
                          max-width: 325px;
                        }
                        @media (min-width: 768px) and (max-width: 959px) {
                          max-width: 321px;
                        }
                        @media (min-width: 960px) and (max-width: 1200px) {
                          max-width: 411px;
                        }
                        @media (min-width: 1201px) {
                          max-width: 576.867px;
                        }
                      `}
                      zIndex={10}
                      className="hp-reskin-syw-address-entry-wrapper"
                    >
                      {fields.analyticsId === 'syw-address-entry-address-entry-validation' ? (
                        <>
                          <Box id="desktop-address-search" display={['none', null, null, 'block']}>
                            <NewSellerFlowAddressInput
                              hideLabel
                              inputLocation="hero-cta"
                              analyticsPrefix={`cosmos-landing-page-hero-v2-with-markers-${
                                fields?.analyticsId ? `-${fields?.analyticsId}` : ''
                              }`}
                              trackingTaxonomy="new_homepage"
                              inNewAddressEntryExperiment={true}
                              placeholder="Enter your home address"
                            />
                          </Box>
                          <Box id="mobile-address-search" display={['block', null, null, 'none']}>
                            <NewSellerFlowAddressInput
                              hideLabel
                              inputLocation="hero-cta"
                              analyticsPrefix={`cosmos-landing-page-hero-v2-with-markers-${
                                fields?.analyticsId ? `-${fields?.analyticsId}` : ''
                              }`}
                              trackingTaxonomy="new_homepage"
                              inNewAddressEntryExperiment={true}
                              placeholder="Enter an address"
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box id="desktop-address-search" display={['none', null, null, 'block']}>
                            <AddressSearch
                              hideLabel
                              inputLocation="hero-cta"
                              analyticsPrefix={`cosmos-landing-page-hero-v2-with-markers-${
                                fields?.analyticsId ? `-${fields?.analyticsId}` : ''
                              }`}
                              trackingTaxonomy="new_homepage"
                              actionText={fields?.addressCtaText || 'Get cash offer'}
                              actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
                              placeholderText={fields?.addressCtaPlaceholderText}
                              showShadow={false}
                            />
                          </Box>
                          <Box id="mobile-address-search" display={['block', null, null, 'none']}>
                            <AddressSearch
                              hideLabel
                              inputLocation="hero-cta"
                              analyticsPrefix={`cosmos-landing-page-hero-v2-with-markers-${
                                fields?.analyticsId ? `-${fields?.analyticsId}` : ''
                              }`}
                              trackingTaxonomy="new_homepage"
                              actionText={fields?.addressCtaText || 'Get cash offer'}
                              actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
                              placeholderText={fields?.addressCtaPlaceholderTextSmall}
                              showShadow={false}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                    <Box
                      width="85%"
                      css={css`
                        min-width: 325px;
                        @media (max-width: 767px) {
                          max-width: 325px;
                          min-height: 108px;
                        }
                        @media (min-width: 768px) and (max-width: 959px) {
                          max-width: 321px;
                          min-height: 108px;
                        }
                        @media (min-width: 960px) and (max-width: 1200px) {
                          max-width: 411px;
                          min-height: 108px;
                          margin-bottom: 110px;
                        }
                        @media (min-width: 1201px) {
                          max-width: 576.867px;
                          min-height: 120px;
                          margin-bottom: 110px;
                        }
                      `}
                    >
                      {showReturnExperienceHeroVariant && <ReturnExperienceHero />}
                    </Box>
                  </>
                )}
              </Flex>
            </Flex>
            <Flex
              width={['100%', null, '50%', '55%']}
              alignItems="center"
              justifyContent={['center', null, 'flex-end']}
              minWidth={[null, null, '350px', null]}
            >
              {/* Desktop hero image */}
              <Flex
                position="relative"
                height={['431px', '543px', 'unset']}
                width="100%"
                alignItems="flex-end"
                display={['none', 'none', 'block', 'block', 'block']}
              >
                {fields?.imageDesktopUrl && (
                  <HeroImageContainer>
                    {generatePresetPictureSet({
                      ...HERO_IMAGE_DESKTOP_PROPS,
                      photoUrl: fields?.imageDesktopUrl,
                    })}
                  </HeroImageContainer>
                )}
              </Flex>
              {/* Mobile hero image */}
              <Flex
                position="relative"
                width="100%"
                alignItems="flex-end"
                display={['block', 'block', 'none', 'none', 'none']}
              >
                {fields?.imageMobileUrl && (
                  <HeroImageContainer>
                    {generatePresetPictureSet({
                      ...HERO_IMAGE_MOBILE_PROPS,
                      photoUrl: fields?.imageMobileUrl,
                    })}
                  </HeroImageContainer>
                )}
              </Flex>
              <LazyLoad offset={300}>
                <Marker
                  imageSource={fields.cashOfferMarkerImage} // Cash offer
                  preload
                  lazy={false}
                  left={[null, '57%', '78%', '77%']}
                  top={[null, '60%', '40%', '29%']}
                  height={[null, '59px', '59px', '80px']}
                  width={[null, '144px', '144px', '195px']}
                  css={MarkerFadeIn}
                  animationDelay="0.5s"
                />
              </LazyLoad>
              <LazyLoad offset={300}>
                <Marker
                  imageSource={fields.newOfferMarkerImage} // New offer
                  preload
                  lazy={false}
                  right={[null, '55%', '28%', '32%']}
                  top={[null, '64%', '47%', '38%']}
                  height={[null, '59px', '59px', '80px']}
                  width={[null, '144px', '144px', '195px']}
                  css={MarkerFadeIn}
                  animationDelay="1.5s"
                />
              </LazyLoad>
              <LazyLoad offset={300}>
                <Marker
                  imageSource={fields.highestOfferMarkerImage} // Highest offer
                  preload
                  lazy={false}
                  right={[null, '41%', '21%', '23%']}
                  top={[null, '50%', '20%', '18%']}
                  height={[null, '59px', '59px', '80px']}
                  width={[null, '144px', '144px', '195px']}
                  css={MarkerFadeIn}
                  animationDelay="2.5s"
                />
              </LazyLoad>
            </Flex>
            {fields?.showPressSection && (
              <Box
                id="desktop-press-box"
                width="555px" // hack to prevent "featured in" from wrapping above the Press logos
                display={['none', null, 'block']}
                left="50%"
                transform="translate(-50%)"
                position="absolute"
                bottom={[null, null, '15px', '60px']}
                css={css`
                  @media (min-width: 768px) and (max-width: 868px) {
                    bottom: -45px;
                  }
                  @media (min-width: 867px) and (max-width: 968px) {
                    bottom: -15px;
                  }
                  @media (max-width: 1048px) and (max-height: 672px) {
                    top: 74vh;
                  }
                  @media (min-width: 1049px) and (max-height: 864px) {
                    top: 76vh;
                  }
                  @media (min-width: 1280px) and (max-height: 864px) {
                    top: 78vh;
                  }
                `}
              >
                <PressBox />
              </Box>
            )}
          </Flex>
        </Box>
        {fields?.showPressSection && (
          <Box
            id="mobile-press-box"
            gridColumn={gridStyles.colSpan12}
            display={['block', null, 'none']}
          >
            <PressBox />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

type IHeroV2WithMarkersLoaderReturn = {
  preloadImages: PictureSourceSpec;
  stickyCTA: string | undefined | null;
};

const heroV2WithMarkersLoader: LoaderFn<
  ILpComponentHeroV2WithMarkers,
  IHeroV2WithMarkersLoaderReturn
> = async (input, root?: Entry<ILandingPageFields>) => {
  const preloadImages: PictureSourceSpec = [];
  /*
    To optimize loading images required for this hero component, let's generate
    the picture source sets to use in preloading
  */
  // Hero image
  if (input.fields.imageDesktopUrl) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_DESKTOP_PROPS,
      photoUrl: input.fields.imageDesktopUrl,
    });
    preloadImages.push(...sources);
  }
  if (input.fields.imageMobileUrl) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_MOBILE_PROPS,
      photoUrl: input.fields.imageMobileUrl,
    });
    preloadImages.push(...sources);
  }

  const loaderReturn: IHeroV2WithMarkersLoaderReturn = {
    preloadImages,
    stickyCTA: root?.fields?.stickyCta || null,
  };

  return loaderReturn;
};

const HeroV2WithMarkers: EntryComponent<
  ILpComponentHeroV2WithMarkers,
  Awaited<ReturnType<typeof heroV2WithMarkersLoader>>
> = {
  render: RenderHeroV2WithMarkers,
  loader: heroV2WithMarkersLoader,
};

export default HeroV2WithMarkers;
